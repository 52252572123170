var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-step" }, [
    _c("div", { staticClass: "permissions-step__body" }, [
      _c("div", { staticClass: "permissions-step__title" }, [
        _vm._v(" " + _vm._s(_vm.session.name) + " "),
      ]),
      _c("div", { staticClass: "permissions-step__info-group ml-3 mr-3" }, [
        _vm.state === _vm.State.PermissionForbidden
          ? _c("div", { staticClass: "permissions-step__error mb-3" }, [
              _vm._v(" MIC AND CAM PERMISSION NOT GRANTED "),
            ])
          : _vm._e(),
        _vm.text
          ? _c("div", { staticClass: "permissions-step__text" }, [
              _vm._v(" " + _vm._s(_vm.text) + " "),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "permissions-step__nav" },
        [
          _vm.state === _vm.State.PermissionDenied
            ? [
                _c(
                  "button",
                  {
                    staticClass: "permissions-step__btn",
                    class:
                      _vm.countdown < 1
                        ? null
                        : "permissions-step__btn--disabled",
                    on: {
                      click: function ($event) {
                        return _vm.okay()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " Okay " +
                        _vm._s(
                          _vm.countdown > 0 ? "(" + _vm.countdown + ")" : ""
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm.state === _vm.State.PermissionRequested
            ? [
                _c(
                  "button",
                  {
                    staticClass:
                      "permissions-step__btn permissions-step__btn--unwanted",
                    on: {
                      click: function ($event) {
                        return _vm.no()
                      },
                    },
                  },
                  [_vm._v(" No ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "permissions-step__btn",
                    on: {
                      click: function ($event) {
                        return _vm.yes()
                      },
                    },
                  },
                  [_vm._v("Yes")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }