<template>
  <div class="permissions-step">
    <div class="permissions-step__body">
      <div class="permissions-step__title">
        {{ session.name }}
      </div>
      <div class="permissions-step__info-group ml-3 mr-3">
        <div
          class="permissions-step__error mb-3"
          v-if="state === State.PermissionForbidden"
        >
          MIC AND CAM PERMISSION NOT GRANTED
        </div>
        <div class="permissions-step__text" v-if="text">
          {{ text }}
        </div>
      </div>
      <div class="permissions-step__nav">
        <template v-if="state === State.PermissionDenied">
          <button
            class="permissions-step__btn"
            :class="countdown < 1 ? null : 'permissions-step__btn--disabled'"
            @click="okay()"
          >
            Okay {{ countdown > 0 ? `(${countdown})` : "" }}
          </button>
        </template>
        <template v-else-if="state === State.PermissionRequested">
          <button
            class="permissions-step__btn permissions-step__btn--unwanted"
            @click="no()"
          >
            No
          </button>
          <button class="permissions-step__btn" @click="yes()">Yes</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const State = {
  PermissionRequested: "permission-requested",
  PermissionDenied: "permission-denied",
  PermissionPopup: "permission-popup",
  PermissionForbidden: "permission-forbidden"
}

const Dictionary = {
  TextOne:
    "In order to play, you will need to give us permission to access your Camera and Microphone. Are you ready?",
  TextTwo: 'You must tap "Allow" above if you want to access the experience.',
  TextThree:
    "Unfortunately, you can't play unless you give us permission to access your Microphone and Camera.",
  TextFour:
    "Grant the browser permission in your settings or try using an incognito window with the same link."
}

const StateToDictionary = {
  [State.PermissionRequested]: Dictionary.TextOne,
  [State.PermissionPopup]: Dictionary.TextTwo,
  [State.PermissionDenied]: Dictionary.TextThree,
  [State.PermissionForbidden]: Dictionary.TextFour
}

export default {
  name: "PermissionsStep",
  components: {},
  props: {
    session: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      interval: null,
      timeout: null,
      state: State.PermissionRequested,
      State,
      countdown: 0
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    clearTimeout(this.timeout)
  },
  computed: {
    text() {
      return StateToDictionary[this.state]
    }
  },
  methods: {
    okay() {
      if (this.state === State.PermissionDenied) {
        this.state = State.PermissionRequested
        return
      }
    },
    async yes() {
      if (this.state === State.PermissionRequested) {
        try {
          this.state = null
          // we don't want to show "Allow" text if there
          // is no need to allow anything
          this.timeout = setTimeout(() => {
            this.state = State.PermissionPopup
          }, 500)
          await this.$store.dispatch("twilio/tryToEnumerateDevices")
          clearTimeout(this.timeout)
        } catch (e) {
          clearTimeout(this.timeout)
          console.error(e)
          this.state = State.PermissionForbidden

          return
        }

        this.$store.commit("twilio/SET_IS_PERMISSION_GRANTED", true)

        return
      }
    },
    no() {
      if (this.state === State.PermissionRequested) {
        this.state = State.PermissionDenied
        this.countdown = 3
        this.interval = setInterval(() => {
          const n = this.countdown - 1
          if (n < 1) clearInterval(this.interval)
          this.countdown = n
        }, 1000)
        return
      }
    }
  }
}
</script>

<style lang="scss">
.permissions-step {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;

  &__body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    max-height: 70%;
  }

  &__nav {
    display: flex;
    justify-content: space-evenly;
  }

  &__text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #586475;
  }

  &__title {
    color: #23222a;
    font-size: min(6vh, 58px);
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Right Grotesk";
    letter-spacing: -0.5px;
    word-spacing: 4px;
    word-wrap: break-word;
  }

  &__error {
    color: #ff4060;
    font-size: min(4vh, 36px);
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Right Grotesk";
    letter-spacing: -0.5px;
    word-spacing: 4px;
    word-wrap: break-word;
  }

  &__info-group {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 54px;
    margin: 0 7.5px;
    padding: 17px 26px;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    line-height: 20px;
    box-sizing: border-box;
    transition: 0.5s;
    opacity: 0.85;
    background-color: $primary_accent_color;
    color: #fff;

    &:hover {
      opacity: 1;
    }

    &--unwanted {
      background-color: grey;
      color: white;
    }

    &--disabled {
      background-color: grey;
      color: white;
      pointer-events: none;
    }
  }
}
</style>
